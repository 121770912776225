<template>
  <div>
    <b-card no-body>
      <div>
        <!-- Table Fitler -->
        <div class="mb-2">
          <!-- Search Condition -->
          <b-row>
            <b-col sm="3">
              <b-form-group
                class="font-small-3 mt-lg-1"
                label="사이트"
                label-for="message-select"
                label-align-sm="2"
                content-cols-sm="6"
              >
                <b-form-select
                  v-if="authSite"
                  id="messages-site"
                  ref="messages-site"
                  v-model="selectedSite"
                  :options="authSite"
                  class="font-small-3"
                  size="sm"
                  style="color:rgb(229,166,48); "
                >
                  <template
                    v-if="false"
                    #first
                  >
                    <b-form-select-option :value="null">
                      <!-- 선택 -->
                      전체
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col
              offset-sm="3"
              sm="6"
            >
              <b-form-group
                class="font-small-3"
                label-align-sm="3"
                label="발신일자"
                label-for="message-period"
              >
                <b-input-group
                  id="message-period"
                  class="calendar-size font-small-3"
                >
                  <b-form-datepicker
                    v-model="periodFrom"
                    class=""
                    boundary="window"
                    locale="ko"
                    placeholder="시작일"
                    aria-controls="example-input"
                    size="sm"
                    :max="periodTo"
                  />
                  <div class="pl-1 pr-1">
                    ~
                  </div>
                  <b-form-datepicker
                    v-model="periodTo"
                    class=""
                    boundary="window"
                    locale="ko"
                    placeholder="종료일"
                    aria-controls="example-input"
                    size="sm"
                    :min="periodFrom"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="3">
              <b-form-group
                class="font-small-3"
                label="show"
                label-for="message-select"
                label-align-sm="2"
                content-cols-sm="6"
              >
                <b-form-select
                  v-model="perPage"
                  :options="perPageOptions"
                  size="sm"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>
            <b-col
              offset-sm="3"
              sm="6"
            >
              <b-form-group
                class="mb-0 pr-1"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="searchFilter"
                    type="search"
                    placeholder="검색: 상위/추천, 닉네임/이름, 아이디, 전화번호 ..."
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!searchFilter"
                      @click="searchFilter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <!-- table content -->
        <div>
          <b-table
            ref="messagesSendHistoyTable"
            responsive
            show-empty
            hover
            small
            empty-text="No matching records found"
            style="text-align: center; font-size: small"
            :items="paginatedItems"
            :fields="messagesSendHistoyFields"
            :filter="filter"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :current-page="currentPage"
            @row-clicked="fncToggleRowDetails"
            @filtered="onFiltered"
          >
            <template #cell(index)="data">
              {{ totalRows - (perPage * (currentPage - 1) + data.index) }}
            </template>
            <template #cell(senderId)="data">
              <div style="min-width: 5rem">
                {{ data.item.senderId }}
              </div>
            </template>
            <template #cell(reads)="data">
              <div style="align-items: center; min-width: 9rem;">
                <span style="text-align: center; flex-grow: 1; max-width: 4rem;">
                  {{ fncReadsAndTotalCount(data.item.receivers) }}
                </span>
              </div>
            </template>
            <template #cell(createdAt)="data">
              <div
                style="min-width: 11rem;"
                size="sm"
              >
                {{ data.item.createdAt | formatDate('YYYY-MM-DD HH:mm:ss') }}
              </div>
            </template>
            <template #cell(actions)="data">
              <b-button
                v-if="data.item.isDeleted === 'N'"
                size="sm"
                class="d-inline-flex align-items-center"
                variant="outline-secondary"
                style="height: 20px;"
                @click.prevent="updateMessagesDelete(data.item)"
              >
                회수
              </b-button>
              <div
                v-else
                style="color: yellow"
              >
                회수완료
              </div>
            </template>
            <!-- row_detatils -->
            <template #row-details="row">
              <div v-if="row.item._showDetails">
                <b-card
                  class="p-0 text-left"
                  style="background-color: #212026;"
                >
                  <b> 내용 : </b> {{ row.item.message }}
                </b-card>
              </div>
            </template>
          </b-table>
        </div>
        <!-- table footer -->
        <div>
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-left justify-content-left justify-content-sm-start pl-4"
              style="margin-bottom: 0.5rem;"
            >
              <span class="font-small-2">{{ pageFrom }}~{{ pageTo }} / 총 {{ pageOf }} </span>
            </b-col>
            <b-col
              cols="12"
              sm="12"
              class="d-flex align-items-center justify-content-center"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BBadge, BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BFormDatepicker, BInputGroup, BInputGroupAppend, BTable, BPagination,
} from 'bootstrap-vue'

import {
  FETCH_MESSAGES_MASTER_TO_ADMIN,
} from '@/store/messages/action'
import {
  UPDATE_MESSAGES, UPDATE_MESSAGES_DELETE,
} from '@/store/messages/mutation'

import { createNamespacedHelpers } from 'vuex'
import * as moment from 'moment-timezone'

import fncShowSwal from './_vueSweetalert2'

const messagesStore = createNamespacedHelpers('messagesStore')

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BPagination,
  },
  data() {
    // data 속성 정의
    return {
      // local Storage
      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      selectedSite: localStorage.getItem('authSiteSelected'),

      msgRole: window.location.href.split('/').pop(),
      msgType: 'snd', // 수신/발신

      // Table Search & Option
      periodFrom: moment(new Date()).startOf('month').format(fmt1),
      periodTo: moment(new Date()).endOf('month').format(fmt1),

      searchFilter: '',
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1, // 현재 페이지
      perPage: 10, // 페이지 당 표시할 항목 수
      pageFrom: 0,
      pageTo: 0,
      pageOf: 0,
      totalRows: 0,
      sortBy: 'createdAt',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      // Table Fields
      messagesSendHistoyFields: [
        { key: 'index', label: 'No.' },
        { key: 'site', label: '사이트' },
        { key: 'title', label: '제목', tdClass: 'text-over text-left' },
        { key: 'receiverId', label: '수신자' },
        { key: 'senderId', label: '발송자' },
        { key: 'senderIp', label: '발송 IP' },
        { key: 'createdAt', label: '발송 일시' },
        { key: 'actions', label: '회수' },
      ],
      // Modal
      // messagesReceivers: [],
    }
  },
  computed: {
    // computed 속성 정의
    ...messagesStore.mapGetters({
      fetchMessagesMasterToAdmin: 'fetchMessagesMasterToAdmin',
    }),

    // Table Search Items
    filteredItems() {
      let filteredData = this.fetchMessagesMasterToAdmin
      if (this.selectedSite) {
        filteredData = filteredData.filter(item => item.site.includes(this.selectedSite))
      }
      if (this.searchFilter) {
        filteredData = filteredData.filter(item => Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase())))
      }
      return filteredData
    },
    // Table Search Items => Pagination Items
    paginatedItems() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      const paginatedData = this.filteredItems.slice(start, end)

      this.totalRows = this.filteredItems.length
      this.pageFrom = start + 1
      this.pageTo = start + paginatedData.length
      this.pageOf = this.totalRows

      return paginatedData
    },
  },
  watch: {
    selectedSite: {
      handler(event) {
        localStorage.setItem('authSiteSelected', event || localStorage.getItem('authSiteSelected'))
        this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
        if (['admin', 'user'].includes(this.msgRole)) {
          this.fetchData()
        }
      },
      immediate: false,
      deep: false,
    },
    periodFrom() {
      this.fetchData()
    },
    periodTo() {
      this.fetchData()
    },
  },
  created() {
    // created 라이프사이클 훅 정의
    // URL 변경 감지 예시
    this.$watch('$route', (to, from) => {
      console.log(`URL이 변경되었습니다. 이전 경로: ${from.path}, 새 경로: ${to.path}`)
      this.msgRole = window.location.href.split('/').pop()
      // URL이 변경될 때 수행할 작업을 여기에 추가
      this.fetchData()
    })
  },
  mounted() {
    // mounted 라이프사이클 훅 정의
    this.fetchData()
  },
  methods: {
    ...messagesStore.mapActions({
      $fetchMessagesMasterToAdmin: FETCH_MESSAGES_MASTER_TO_ADMIN,
      $updateMessagesDelete: UPDATE_MESSAGES_DELETE,
    }),
    async fetchData() {
      const periodFrom = this.periodFrom ? `${moment(this.periodFrom).format('YYYY-MM-DD')} 00:00:00` : ''
      const periodTo = this.periodTo ? `${moment(this.periodTo).format('YYYY-MM-DD')} 23:59:59` : ''

      const param = {
        site: this.selectedSite,
        userid: this.userData.userid,
        role: this.msgRole || this.userData.role,
        type: this.msgType,
        periodFrom,
        periodTo,
      }
      await this.$fetchMessagesMasterToAdmin(param)
    },
    // Table methods
    fncReadsAndTotalCount(receivers) {
      const total = receivers ? receivers.length : 0
      const readCount = receivers ? receivers.filter(receiver => receiver.isRead).length : 0
      return `${readCount} / ${total} 명`
    },
    fncToggleRowDetails(item) {
      this.$set(item, '_showDetails', !item._showDetails)
    },
    async updateMessagesDelete(item) {
      await this.$updateMessagesDelete({
        mId: item.mId,
        site: this.selectedSite,
      })
      await this.fetchData()
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style>
.dropdown .btn {
  padding: 0;
}
</style>
